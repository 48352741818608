<template>
  <div>
    <b-modal
      id="modal-export-weighing-note"
      size="xl"
      :title="posyandu_name == '' ? 'Catatan Timbang Balita di semua posyandu' :  `Catatan Timbang Balita di posyandu ${posyandu_name}`"
      body-class=""
      hide-footer
    >
      <div class="row justify-content-start">
        <vue-excel-xlsx
          :data="itemsNoPagination"
          :columns="columns"
          :filename="posyandu_name == '' ? 'Timbang Balita semua posyandu' : `Timbang Balita ${posyandu_name}`"
          :sheetname="posyandu_name == '' ? 'Timbang Balita semua posyandu' : `Timbang Balita ${posyandu_name}`"
        >
          <b-button
            class="mr-1"
            style="background-color: #107c41"
            v-b-tooltip.hover
            title="Download Excel"
          >
            <i class="fas fa-file-excel text-white px-0"></i>
          </b-button>
        </vue-excel-xlsx>
      </div>

      <b-table
        striped
        hover
        :items="itemsNoPagination"
        :fields=" currentUser.role_key_name == 'super_admin' || currentUser.user_type_id == 3 ? fieldsSpecial: fields
        "
        class="mt-3"
        responsive
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'action' ? '25%' : '' }"
          />
        </template>

        <template #cell(number)="data">
            {{data.index + 1}}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  props: {
    items: Array,
    itemsNoPagination: Array,
    posyandu: Object,
    posyandu_name: String
  },
  data() {
    return {
      currentUser: getUser(),
      fields: [
        {
          key: "number",
          label: "No",
        },
        {
          key: "weighing_date_display",
          label: "Tgl Nimbang",
        },
        {
          key: "registration_number",
          label: "NIK Balita",
        },
        {
          key: "toddler_name",
          label: "Nama Balita",
        },
        {
          key: "weight",
          label: "Berat Badan",
        },
        {
          key: "height",
          label: "Panjang Badan",
        },
        {
          key: "head_circumference",
          label: "Lingkar Kepala",
        },
        {
          key: "is_vitamin_display",
          label: "Mendapat Vitamin",
        },
        {
          key: "vitamin_list_display_excel",
          label: "Vitamin",
        },
        {
          key: "is_immunization_display",
          label: "Mendapat imunisasi",
        },
        {
          key: "immunization_list_display_excel",
          label: "Imunisasi",
        },
        {
          key: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Ekslusif",
        },
        {
          key: "is_exclusive_breastfeeding_display",
          label: "Mendapat MPASI ?",
        },
      ],
      fieldsSpecial: [
        {
          key: "number",
          label: "No",
        },
        {
          key: "posyandu_name",
          label: "Posyandu",
        },
        {
          key: "weighing_date_display",
          label: "Tgl Nimbang",
        },
        {
          key: "registration_number",
          label: "NIK Balita",
        },
        {
          key: "toddler_name",
          label: "Nama Balita",
        },
        {
          key: "weight",
          label: "Berat Badan",
        },
        {
          key: "height",
          label: "Panjang Badan",
        },
        {
          key: "head_circumference",
          label: "Lingkar Kepala",
        },
        {
          key: "is_vitamin_display",
          label: "Mendapat Vitamin",
        },
        {
          key: "vitamin_list_display_excel",
          label: "Vitamin",
        },
        {
          key: "is_immunization_display",
          label: "Mendapat imunisasi",
        },
        {
          key: "immunization_list_display_excel",
          label: "Imunisasi",
        },
        {
          key: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Ekslusif",
        },
        {
          key: "is_exclusive_breastfeeding_display",
          label: "Mendapat MPASI ?",
        },
      ],
      columns: [
        {
          field: "weighing_date_display",
          label: "Tgl Nimbang",
        },
        {
          field: "registration_number",
          label: "NIK Balita",
        },
        {
          field: "toddler_name",
          label: "Nama Balita",
        },
        {
          field: "weight",
          label: "Berat Badan",
        },
        {
          field: "height",
          label: "Panjang Badan",
        },
        {
          field: "head_circumference",
          label: "Lingkar Kepala",
        },
        {
          field: "is_vitamin_display",
          label: "Mendapat Vitamin",
        },
        {
          field: "vitamin_list_display_excel",
          label: "Vitamin",
        },
        {
          field: "is_immunization_display",
          label: "Mendapat imunisasi",
        },
        {
          field: "immunization_list_display_excel",
          label: "Imunisasi",
        },
        {
          field: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Ekslusif",
        },
        {
          field: "is_exclusive_breastfeeding_display",
          label: "Mendapat MPASI",
        },
      ],
      columnsSpecial: [
        {
          field: "posyandu_name",
          label: "Posyandu",
        },
        {
          field: "weighing_date_display",
          label: "Tgl Nimbang",
        },
        {
          field: "registration_number",
          label: "NIK Balita",
        },
        {
          field: "toddler_name",
          label: "Nama Balita",
        },
        {
          field: "weight",
          label: "Berat Badan",
        },
        {
          field: "height",
          label: "Panjang Badan",
        },
        {
          field: "head_circumference",
          label: "Lingkar Kepala",
        },
        {
          field: "is_vitamin_display",
          label: "Mendapat Vitamin",
        },
        {
          field: "vitamin_list_display_excel",
          label: "Vitamin",
        },
        {
          field: "is_immunization_display",
          label: "Mendapat imunisasi",
        },
        {
          field: "immunization_list_display_excel",
          label: "Imunisasi",
        },
        {
          field: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Ekslusif",
        },
        {
          field: "is_exclusive_breastfeeding_display",
          label: "Mendapat MPASI",
        },
      ],
    };
  },
};
</script>

<style>
</style>